import { ImTree } from 'react-icons/im';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { IBusinessUnit } from '../../../../interfaces/IBusinessUnit';
import api from '../../../../services/api';
import { UFS_SELECT_OPTIONS } from '../../../../utils/shared-functions';
import { Container } from '../../../Behaviour-Questions/styles';
import { mapAddBusinessUnitErrors } from './ModalFormUnit.helpers';

interface ModalFormUnitProps {
  onCloseModal: () => void;
  onSetBusinessUnit: (businessUnit: IBusinessUnit, update?: boolean) => void;
  isOpen: boolean;
  businessUnit: IBusinessUnit | undefined;
  unidadeEmpresaPaiId?: number;
}

export const ModalFormUnit = ({
  onCloseModal, isOpen, businessUnit, onSetBusinessUnit, unidadeEmpresaPaiId,
}: ModalFormUnitProps) => {
  const [loading, setLoading] = useState(false);
  const [uf, setUf] = useState<any>();

  const initialValue: IBusinessUnit = {
    id: 0,
    nome: '',
    uf: '',
    matrix: false,
    metaNps: 1,
    endereco: '',
    cep: '',
  };

  useEffect(() => {
    if (businessUnit) {
      setUf({
        value: businessUnit.uf,
        label: businessUnit.uf,
      });
    }
  }, [businessUnit]);

  const handleSubmit = useCallback(async (values: IBusinessUnit) => {
    setLoading(true);
    try {
      const formattedData = { ...values, unidadeEmpresaPaiId };

      if (businessUnit) {
        const { data } = await api.put(`/unidade-empresa/${businessUnit.id}`, formattedData);
        onSetBusinessUnit(data.data, true);
      } else {
        const { data } = await api.post('/unidade-empresa', formattedData);
        onSetBusinessUnit(data.data, false);
      }
    } catch (error: any) {
      if (error.response.data.errors) {
        const { errors } = error.response.data as { errors: string[] };

        // It is not that good to rely on server errors and display to the customer
        errors.forEach((errorMessage) => toast.error(mapAddBusinessUnitErrors(errorMessage)));
      } else {
        // If its an error we don't have a clue.
        toast.error(
          'Houve um problema ao adicionar essa unidade. Contate nosso suporte.',
        );
      }

      onCloseModal();
    } finally {
      setLoading(false);
    }
  }, [businessUnit, onCloseModal, onSetBusinessUnit]);

  const schema = yup.object({
    nome: yup.string().required('O nome é obrigatório'),
    metaNps: yup.number().required('O NPS é obrigatório'),
  });

  const SelectUFs = (
    { field, ...props } : any,
  ) => (
    <div style={{ marginTop: '0', paddingTop: '0.2rem' }}>
      <label style={{ marginBottom: '0.5rem' }} className="label">
        UF
        {' '}
      </label>
      <Select
        name={field.name}
        isDisabled={props.disabled}
        options={UFS_SELECT_OPTIONS}
        defaultValue={uf?.value || UFS_SELECT_OPTIONS[0].value}
        value={UFS_SELECT_OPTIONS.find((element) => element.value === field.value || 0)}
        onChange={(option: any) => {
          props.form.setFieldValue('uf', option?.value, false);
          setUf(option?.value);
        }}
      />
      <ErrorMessage name={field.name} component="span" className="error is-invalid red-color" />
    </div>
  );

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={isOpen}
      title="Incluir Unidade de Empresa"
      icon={ImTree}
      size="lg"
    >
      <h6>Preencha todos os dados solicitados.</h6>
      <Formik
        initialValues={businessUnit || initialValue}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="text" name="nome" label="Nome" isRequerid />
          <Input type="number" min={1} max={100} name="metaNps" label="Meta NPS" isRequerid />
          <Input type="text" name="endereco" label="Endereço" />
          <Input type="number" name="cep" mask="99999-999" label="CEP" />

          <Container>
            <Field
              name="uf"
              component={SelectUFs}
              isRequerid
            />
          </Container>

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
